.ubf-excerpt {
	position: relative;

	&__text {
		@include ubf-font(base);

		* + & {
			margin-top: $base;

			.ubf-sidebar & {
				margin-top: 0;
			}
		}
	}



	&__title {
		@include ubf-font(h3);
		page-break-inside: avoid;
		position: relative;

		.ubf-sidebar & {
			@include ubf-font(h);
		}
	}
}
