.ubf-button {
	@include button();
	@include ubf-highlightColor(background-color border-color);
	@include ubf-font(base);
	@include ease(background-color, $duration2);
	border-width: $buttonBorderSize;
	border-style: solid;
	color: $colorBase;
	font-weight: bold;
	min-width: $buttonMinWidth;
	padding: $inputPadding;
	text-align: center;

	&--pagination {
		min-width: $base4;
	}

	&--seamless {
		@include ubf-link();
		background-color: transparent;
		border-color: transparent;
		padding-left: 0;
		padding-right: 0;
		min-width: 0;

		@include onHover {
			outline: 0;
		}
	}


	&--link {
		@include ubf-link();
		background-color: transparent;
		border-color: transparent;
		padding: 0;
		min-width: 0;
		text-align: left;

		@include onHover {
			outline: 0;
		}

		.ubf-form--login & {
			@include ubf-link($boxed: true);
		}
	}


	&--secondary {
		background-color: transparent;
	}

	.ubf-field--search & {
		min-width: $buttonMinWidth;
	}


	&__icon {
		.ubf-button--pagination & {
			height: $base;
			width: auto;
		}

		.ubf-button--pagination.ubf-button--prev & {
			position: relative;
			transform: rotate(180deg);
		}
	}
}
