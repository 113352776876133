.ubf-slide {
	&__caption {
		@include ubf-font(caption);
		color: $colorBgBase;
		left: 0;
		padding: $base 0 0 0;
		position: absolute;
		text-align: right;
		top: 100%;
		width: 100%;
	}


	&__content {
		position: relative;
	}


	&__image {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		.ubf-section--cover & {
			display: none;
		}
	}


	&__picture {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
}
