.ubf-header {
	position: relative;
	height: $logoMargin * 3 + $logoSize + $logoSize * $nameLogoRatio + $base5;

	&__facultyName {
		z-index: 3;
	}


	&__facultySvg {
		@include mediaMap($sizeVariants, facultyGeometry);
		// fix the alignment with the logo, because the name svg is containing 1px empty around
		// margin-top: 1px;
		position: absolute;
	}

	&__campaignSvg {
		@include mediaMap($sizeVariants, campaignGeometry);
		position: absolute;
	}


	&__logo {
		z-index: 10;


		&::before {
			@include mediaMap($sizeVariants, logoBarGeometry);
			@include ubf-highlightColor(background-color);
			content: '';
			display: none;
			position: absolute;

			.ubf-body--faculty & {
				display: block;
			}
		}
	}


	&__logoSvg {
		@include mediaMap($sizeVariants, logoGeometry);
		color: $colorBase;
		position: absolute;
	}


	&__mainMenuToggler {
		display: none;
	}


	&__name {
		z-index: 2;
	}


	&__nameSvg {
		@include mediaMap($sizeVariants, nameGeometry);
		color: $colorBase;
		position: absolute;
	}
}
