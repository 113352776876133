.ubf-address {
	font-style: normal;


	&__detail {
		& + & {
			margin-top: $base;
		}
	}


	&__details {
		* + & {
			margin-top: $base;
		}
	}


	&__detailName {
		display: inline-block;
		margin: 0;

		&::after {
			content: ':';
		}
	}


	&__detailValue {
		display: inline-block;
		margin: 0;
	}


	&__image {
		@include aspectRatio(1);
		position: relative;
	}


	&__info {
		@include ubf-highlightColor(border-color);
		border-style: solid;
		border-width: $boxBorderSize 0;
		padding: $base2 0;

		.ubf-address__image + & {
			border-top-width: 0;
		}
	}


	&__name {
		@include ubf-font(h);


		.ubf-address__title + & {
			margin-top: $base;
		}
	}


	&__picture {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}


	&__role {
		@include ubf-font(base);

		.ubf-address__title + & {
			margin-top: $base;
		}
	}


	&__title {
		@include ubf-font(h3);
	}
}
