.ubf-dayEvent {
	background-color: $colorBgBase;

	.ubf-accordion & {
		background-color: $colorEkvvBgAlternative;
	}


	&__content {
		@include ubf-font(base);
		padding: $base;
		position: relative;
		// we need to override an inline style only on small screen, so this is required
		width: 100% !important; // stylelint-disable-line



		&--full {
			display: none;
		}
	}


	&__icon {
		display: none;
	}


	&__info {
		@include ubf-font(weekEventInfo);
		margin-right: $base2;

		.ubf-dayEvent__title + & {
			margin-top: $base;
		}
	}


	&__link {
		color: inherit;
		text-decoration: none;

		&::before {
			content: '';
			display: block;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 1;
		}
	}


	&__removeLink {
		display: none;
	}


	&__title {
		@include ubf-font(base);
		font-weight: bold;
		padding-right: $base2;
	}
}
