.ubf-page {
	position: relative;

	&__secondaryMenu {
		display: none;
	}


	&__section {
		position: relative;
		// margin-left: $sectionHorizontalSpace;
		// margin-right: $sectionHorizontalSpace;

		& + & {
			margin-top: $sectionVerticalSpace;
		}
	}


	&__separator {
		@include ubf-highlightColor(border-top-color);
		border-width: 0;
		border-top-width: $boxBorderSize;
		border-top-style: solid;
		height: 0;
		margin-bottom: $sectionVerticalSpace;
		margin-top: $sectionVerticalSpace;
		position: relative;
		width: 100%;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}
