.ubf-galleryItem {
	&__caption {
		@include ubf-font(caption);
		color: $colorBgBase;
		flex-grow: 0;
		flex-shrink: 0;
		padding: $base 0 0 0;
		text-align: right;
	}


	&__image {
		@include aspectRatio(1);
		position: relative;
	}


	&__link {
		color: currentColor;
		display: block;
		text-decoration: none;
		pointer-events: none;
	}


	&__picture {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
}
