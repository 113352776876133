.ubf-section {
	position: relative;

	&__bg {
		display: none;
	}


	&__content {
		@include dynamicColumns($hSpace: $gridGutter, $vSpace: $gridGutter, $hSpacePos: left, $vSpacePos: bottom);
		position: relative;

		.ubf-section__title + & {
			margin-top: $base3;
		}
	}


	&__picture {
		display: none;
	}


	&__separator {
		@include ubf-highlightColor(border-top-color);
		border-width: 0;
		border-top-width: $boxBorderSize;
		border-top-style: solid;
		height: 0;
		margin-bottom: $base4;
		margin-top: $base4;
		width: 100%;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}


	&__title {
		@include ubf-font(h2);
		page-break-inside: avoid;
		position: relative;

		.ubf-body--home & {
			@include ubf-font(h1);
		}
	}
}
