.ubf-eventsDetails {
	display: flex;
	flex-direction: column;


	&__dayOfWeek {
		display: block;
	}


	&__excerpts {
		* + & {
			margin-top: $base2;
		}
	}


	&__title {
		@include ubf-font(h2);
		flex-grow: 1;

		.ubf-body--home & {
			@include ubf-font(h1);
		}
	}
}
