.ubf-ugc {
	// User Generated Content Style


	a {
		@include ubf-link();
	}


	button {
		border: 0;
		vertical-align: middle;
		display: inline-flex;
		justify-content: center;
		align-items: center;
	}


	figcaption {
		@include ubf-font(caption);
		margin-top: $base;
		text-align: right;
	}


	figure {
		width: 100%;

		img {
			width: 100%;
		}
	}


	& &__image {
		@mixin noFloat($resetWidth: true) {
			float: none;
			margin-bottom: 0;
			margin-right: 0;
			@if ($resetWidth) {
				width: 100%;
			}
		}

		@mixin floatRight() {
			float: right;
			margin-left: $gridGutter;
			margin-right: 0;
		}


		&--medium {
			float: left;
			margin-bottom: $gridGutter;
			margin-right: $gridGutter;
			width: staticColumnSize(4, 6, $gridGutter);

			&.ubf-ugc__image--right {
				@include floatRight();

				.ubf-sidebar & {
					@include noFloat();
				}
			}

			&.ubf-ugc__image--noFloat {
				@include noFloat(false);
			}

			.ubf-sidebar & {
				@include noFloat();
			}
		}


		&--small {
			float: left;
			margin-bottom: $gridGutter;
			margin-right: $gridGutter;
			width: staticColumnSize(3, 6, $gridGutter);


			&.ubf-ugc__image--right {
				@include floatRight();

				.ubf-sidebar & {
					@include noFloat();
				}
			}

			&.ubf-ugc__image--noFloat {
				@include noFloat(false);
			}

			.ubf-sidebar & {
				@include noFloat();
			}
		}
	}


	q,
	blockquote {
		quotes: "»" "«" "\00AB" "\00BB";
	}


	blockquote {
		@include ubf-font(h3);
		@include ubf-highlightColor(border-left-color);
		font-weight: normal;
		margin: 0;
		padding-left: $base2;
		border-left-style: solid;
		border-left-width: $boxBorderSize;

		> :first-child::before {
			content: open-quote;
		}

		> :last-child::after {
			content: close-quote;
		}


		.ubf-preview &,
		.ubf-sidebar & {
			@include ubf-font(base);
			padding-left: 0;
			border-left: 0;
		}
	}


	cite {
		@include ubf-font(base);
		display: block;
		font-style: normal;
		text-align: right;


		&::before {
			content: '\2014';
			margin-right: 0.2em;
		}
	}

	blockquote cite {
		margin-top: $base;
	}

	q + cite {
		display: inline;
		margin-left: $base;
	}


	q {
		font-style: normal;
		color: $colorInlineQuote;
	}


	dl {
		margin: 0;

		&.ubf-ugc__dl--compact {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			width: 100%;
		}

		&.ubf-ugc__dl--splitted {
			display: flex;
			flex-wrap: wrap;
		}

		&.ubf-ugc__dlItemsWrapper {
			margin-top: $base2;
			width: staticColumnSize(3, 6, $gridGutter);
		}

		&.ubf-ugc__dl--floating {
			display: flex;
			flex-direction: row;
		}
	}


	dt {
		@include ubf-font(base);
		display: block;
		font-weight: bold;
	}


	.ubf-ugc__dl--compact dt {
		flex-grow: 0;
		flex-shrink: 0;
		font-weight: normal;
		padding-right: $base;
		width: 30%;
	}


	.ubf-ugc__dl--floating dt {
		font-weight: normal;
		width: auto;
		margin-top: 0;
	}


	dd {
		margin: 0;
	}


	.ubf-ugc__dl--compact dd {
		flex-grow: 0;
		flex-shrink: 0;
		width: 70%;
	}


	.ubf-ugc__dl--compact dd + dd {
		margin-left: 30%;
	}


	.ubf-ugc__dl--floating dd {
		width: auto;
		flex-direction: row;
		margin: 0 $base2 0 $base1 * 0.5;
	}



	// this should never be used inside content,
	// we set the same size as h2 to discourage any use to obtain a bigger title
	h1 {
		@include ubf-font(h2);

		.ubf-sidebar & {
			@include ubf-font(h3);
		}
	}


	h2 {
		@include ubf-font(h2);

		.ubf-sidebar & {
			@include ubf-font(h3);
		}
	}


	h3 {
		@include ubf-font(h3);

		.ubf-sidebar & {
			@include ubf-font(h);
		}
	}


	h4,
	h5,
	h6 {
		@include ubf-font(h);
	}



	hr {
		@include ubf-highlightColor(background-color);
		border: 0;
		height: $boxBorderSize;
		margin: 0;
		width: 100%;
	}


	li {
		margin: 0;
	}

	ol > li {
		padding-left: 0.4em;
	}


	ol {
		margin: 0 0 0 1em;
	}


	ul {
		margin: 0 0 0 1em;
	}


	ul > li {
		position: relative;

		&::before {
			content: '\2022';
			position: absolute;
			top: 0;
			transform-origin: 0 50%;
			transform: scale(1.5);
			left: -$base2;
		}
	}

	ul.ubf-ugc__list--indentationFreeList {
		margin-left: 0;

		> li {
			margin-top: $base2;
		}

		> li::before {
			content: '';
			display: none;
		}

		> li p {
			margin-top: 0;
		}
	}


	ul.ubf-ugc__list--rowList {
		display: flex;
		list-style-type: none;
		margin-left: 0;
		flex-wrap: wrap;
		justify-content: space-between;
	}


	li.ubf-ugc__listItem--rowListItem {
		@include ubf-font(h3);
		margin: 0;


		&::before {
			content: '';
			display: none;
		}


		a {
			text-decoration: none;
		}


		p {
			color: $colorAlphabeticalLetterInactive;
		}


		p > a {
			color: $colorBase;
		}
	}


	table {
		display: table;
		width: 100%;
	}


	caption {
		@include ubf-font(caption);
		caption-side: bottom;
		border-top: $tableCellBorderSize solid $colorTableCellBorder;
		padding: $baseHalf 0 0 0;
		text-align: right;
	}


	tr {
		display: table-row;
	}

	&__table--alternative tr:nth-child(2n + 1) {
		background-color: $colorBgTableAlternativeRows;
	}

	&__table--semesterTable tr {
		display: table-row;

		&:not(:last-child):not(:first-child) {
			border-bottom: 0;
		}

		&:first-child {
			border-bottom: $colorEkvvTableBorder solid $tableHeaderBorderSize;
		}

		// nth child repeticion needed to override alternative variant
		&:nth-child(2n + 1) {
			background-color: $colorBgSemesterTable;
		}

		&:nth-child(2n + 2) {

			background-color: $colorBgBase;
		}
	}


	th {
		font-weight: bold;
		@include ubf-highlightColor(border-bottom-color);
		border-bottom-width: $tableHeaderBorderSize;
		border-bottom-style: solid;
		display: table-cell;
		vertical-align: top;
		padding: $baseHalf $base2 $baseHalf 0;

		&:last-child {
			padding-right: 0;
		}
	}


	&__table--alternative th {
		border: 0;

		&:first-child {
			padding-left: $base;
		}
	}

	&__table--semesterTable th {
		text-align: left;
	}


	td {
		border-bottom: $tableCellBorderSize solid $colorTableCellBorder;
		font-weight: normal;
		display: table-cell;
		padding: $baseHalf $base2 $baseHalf 0;
		vertical-align: top;

		&:last-child {
			@include ubf-highlightColor(border-bottom-color);
			border-bottom-width: $tableHeaderBorderSize;
			border-bottom-style: solid;

			.ubf-body & {
				border-bottom: $tableCellBorderSize solid $colorTableCellBorder;
				padding-right: 0;
			}
		}
	}


	&__table--alternative td:first-child {
		padding-left: $base;
	}


	&__table--semesterTable td {
		border-bottom: 0;
		margin-right: $base1;

		&:first-child {
			flex-grow: 1;
			width: auto;
		}

		// last child repeticion needed to override alternative variant
		&:last-child {
			border-bottom: 0;
		}
	}


	&__table--semesterTable th {
		font-weight: bold;
		display: table-cell;
		padding-bottom: $base1;

		&:not(:first-child) {
			display: table-cell;
		}
	}


	tr:last-of-type td {
		border-bottom: 0;

		&:last-child {
			border-bottom: 0;
		}
	}


	// th content added dynamically in each cell in order to be displayed on small screens
	[data-ubf-nested-header] {
		display: none;
	}


	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	ol,
	ul,
	dl,
	blockquote {
		.ubf-form &,
		.ubf-contentBlock--textFull & {
			max-width: staticColumnSize(12, 12, $gridGutter);

			.ubf-body--generic &,
			.ubf-body--internal & {
				max-width: staticColumnSize(9, 9, $gridGutter);
			}
		}

		.ubf-body--ekvv & {
			max-width: ubf-ekvvMaxSize(9, 9);
		}
	}


	* + a > figure {
		margin-top: 1em;
	}


	// space before the headings
	* + h1,
	* + h2,
	* + h3,
	* + h4,
	* + h5,
	* + h6 {
		margin-top: $base3;
	}


	// space before all common elements in the content
	* + table,
	* + figure,
	* + p,
	* + ol,
	* + ul,
	* + dl,
	dd + dd,
	dt + dt {
		margin-top: 1em;
	}


	// space between list items
	li + li {
		margin-top: $base;
	}


	// special style between definition list items
	dd + dt {
		border-top: $tableCellBorderSize solid $colorTableCellBorder;
		margin-top: $base2;
		padding-top: $base2;
	}


	.ubf-ugc__dl--compact dd + dt,
	.ubf-ugc__dl--compact dd + dd,
	.ubf-ugc__dl--floating dd + dt,
	.ubf-ugc__dl--floating dd + dd {
		border-top: 0;
		margin-top: 0;
		padding-top: 0;
	}


	.ubf-ugc__dl--alphabeticalList {
		& dd + dt {
			border-top: $tableCellBorderSize solid $colorAlphabeticalDlBorder;
		}

		& dt {
			margin-bottom: $base1;
		}

		& dd:last-of-type {
			margin-bottom: $base4;
		}
	}


	// special margin around the blockquote
	* + blockquote,
	blockquote + * {
		margin-top: $base2;
	}

	// special margin around hr separator
	* + hr,
	hr + * {
		margin-top: $base2;
	}


	&__text {
		&--helpText {
			@include ubf-font('helpText');
		}

		&--date {
			@include ubf-font('date');
		}

		&--important {
			font-weight: bold;
			color: $colorTextImportant;
		}
	}
}

