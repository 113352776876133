.ubf-highlights {
	position: relative;

	// &__excerpts {
	// 	* + & {
	// 		margin-top: $base2;
	// 	}
	// }


	&__item {
		position: relative;
		display: none;

		&--news {
			display: block;
		}
	}
}
