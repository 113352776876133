.ubf-slideshow {
	position: relative;

	.ubf-modal & {
		display: none;
	}


	&__navigation {
		display: none;
	}


	&__slide {
		display: none;

		.ubf-section:not(.ubf-section--cover) & {
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}

		&:first-child {
			display: block;
		}
	}


	&__slides {
		position: relative;
		margin-bottom: $base3;

		.ubf-section:not(.ubf-section--cover) & {
			@include aspectRatio($gallerySlidesRatio);
		}
	}
}
