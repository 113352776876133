.ubf-form {
	&__button {
		margin-left: $base2;

		.ubf-form--login &:last-child {
			margin-top: $base2;
		}
	}


	&__buttons {
		// this removes the space between inline-blocks / inline-flex elements.
		// the proper font size is set in the children
		font-size: 0;
		margin-left: -$base2;

		* + & {
			margin-top: $base3;

			.ubf-form--login & {
				margin-top: $base2;
			}
		}

		.ubf-form__separator + & {
			margin-top: $base4;
		}
	}


	&__field {
		width: dynamicColumnSize(4, 9, $gridGutter);

		&--full {
			width: dynamicColumnSize(8, 9, $gridGutter);
		}

		.ubf-form &--alone {
			margin-right: dynamicColumnPush(5, 9, $gridGutter, true);
		}

		.ubf-form__separator + & {
			margin-top: $base4;
		}

		.ubf-form__separator + &:not(&--full):not(&--alone) + &:not(&--full):not(&--alone) {
			margin-top: $base4;
		}
	}


	&__fields {
		@include dynamicColumns($hSpace: $gridGutter, $vSpace: $base3, $hSpacePos: left, $vSpacePos: top);
		position: relative;
	}


	&__separator {
		@include ubf-highlightColor(border-top-color);
		border-width: 0;
		border-top-width: $boxBorderSize;
		border-top-style: solid;
		height: 0;
		margin-bottom: 0;
		display: block;
		width: 100%;

		.ubf-form__fields & {
			width: dynamicColumnSize(9, 9, $gridGutter);
		}

		* + & {
			margin-top: $base4;
		}
	}


	&__text {
		.ubf-form__fields & {
			width: dynamicColumnSize(1, 1, $gridGutter);
		}

		.ubf-form__buttons & {
			@include ubf-font(base);
			margin: $base3 0 $base3 $base2;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		.ubf-form > & {
			margin-bottom: $base3;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.ubf-form > * + & {
			margin-top: $base3;
		}

		.ubf-form__separator + & {
			margin-top: $base4;
		}
	}
}
