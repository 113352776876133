.ubf-previews {
	@include dynamicColumns($hSpace: $gridGutter, $vSpace: $gridGutter, $hSpacePos: right);
	position: relative;

	&__preview {
		width: dynamicColumnSize(1, 3, $gridGutter);

		.ubf-previews--extended & {
			// width: dynamicColumnSize(1, 2, $gridGutter);
		}
	}
}
