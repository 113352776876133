.ubf-preview {
	page-break-inside: avoid;
	position: relative;

	&__picture {
		page-break-inside: avoid;
		position: relative;
		width: 100%;

		.ubf-preview:not(.ubf-preview--noCrop) & {
			// @include aspectRatio(1);
		}
	}

	&__summary {
		@include ubf-highlightColor(border-color);
		border-style: solid;
		border-width: $boxBorderSize 0 0 0;
		padding: $base2 0;

		&:last-child {
			border-bottom-width: $boxBorderSize;
		}

		.ubf-preview__visual + & {
			border-top-width: 0;
		}

		.ubf-body:not(.ubf-body--generic):not(.ubf-body--internal) & {
			border-width: 0;
		}

		.ubf-sidebar .ubf-contentBlock--preview:not(:first-child) & {
			border-top: 0;

			&:first-child {
				padding-top: 0;
			}
		}
	}


	&__text {
		@include ubf-font(base);

		.ubf-preview__title + & {
			margin-top: $base;
		}
	}


	&__thumbLink {
		display: block;
		color: currentColor;
	}


	&__title {
		@include ubf-font(h3);

		.ubf-sidebar & {
			@include ubf-font(h);
		}
	}


	&__visual {
		.ubf-preview__summary + & {
			@include ubf-highlightColor(border-bottom-color);
			border-bottom-style: solid;
			border-bottom-width: $boxBorderSize;
			padding-bottom: $base2;

			.ubf-body:not(.ubf-body--generic):not(.ubf-body--internal) & {
				border-bottom-width: 0;
				padding-bottom: 0;
			}
		}
	}
}
