.ubf-video {
	.ubf-contentBlock--video:not(.ubf-contentBlock--videoSmall) & {
		@include staticColumns();
	}


	&__caption {
		@include ubf-font(caption);
		margin-top: $base;
		text-align: right;

		.ubf-contentBlock--video:not(.ubf-contentBlock--videoSmall) & {
			@include ubf-font(base);
			@include ubf-highlightColor(border-color);
			align-self: flex-start;
			border-style: solid;
			border-width: $boxBorderSize 0;
			margin-top: 0;
			padding: $base2 0;
			text-align: left;
			width: staticColumnSize(3, 9, $gridGutter);
		}
	}


	&__content {
		@include aspectRatio($videoRatio);
		position: relative;


		.ubf-contentBlock--video:not(.ubf-contentBlock--videoSmall) & {
			width: staticColumnSize(6, 9, $gridGutter);
		}
	}


	&__icon {
		height: $base4;
		left: 50%;
		position: absolute;
		top: 50%;
		text-align: center;
		width: $base4;
		margin-left: -$base2;
		margin-top: -$base2;

		[data-type="svg"] {
			width: 100%;
			height: 100%;
		}
	}


	&__img {
		@include objectFit(cover);
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;
	}


	&__link {
		display: block;
		color: $colorBgBase;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 3;
	}


	&__player {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 4;
	}
}
